import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { startMatrixEffect } from './matrix';
import './SeizureBanner.css';

const SeizureBanner = () => {
    const [bgColor, setBgColor] = useState('#859acb');
    const canvasRef = useRef(null);
    const navigate = useNavigate();
    const duration = 2500; // Adjusted total duration for the transition in milliseconds
    const [glitch, setGlitch] = useState(false);

    useEffect(() => {
        const isVisited = Cookies.get('visited');
        if (isVisited === 'yes') {
            window.location.href = 'https://landing.doomer.ai';
        } else {
            const interval = setInterval(() => {
                setBgColor(prevColor => {
                    if (prevColor === 'black') {
                        clearInterval(interval);
                        return prevColor;
                    }
                    return 'black';
                });
            }, 100);

            setTimeout(() => {
                if (canvasRef.current) {
                    startMatrixEffect(canvasRef.current, duration);
                }
            }, 1000);

            setTimeout(() => {
                setBgColor('black');
            }, 1500); // Delay to start the background color transition

            setTimeout(() => {
                setGlitch(true);
                setTimeout(() => {
                    navigate('/transition');
                }, 500); // Duration of the glitch effect
            }, 4000);

            return () => clearInterval(interval);
        }
    }, [navigate]);

    return (
        <div className={`seizure-banner ${glitch ? 'glitch' : ''}`} style={{ position: 'relative', backgroundColor: bgColor, width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center' }}>
                            <img src="banner.jpg" className="flickering" style={{ maxWidth: '1040px', width: '100%' }} alt="Seized banner" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}></td>
                    </tr>
                </tbody>
            </table>
            <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, pointerEvents: 'none' }}></canvas>
        </div>
    );
};

export default SeizureBanner;
