import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { startMatrixEffect } from './matrix';
import './TransitionPage.css';

const commands = [
    { text: "CONNECTING TO BLOCKCHAIN", effect: 'shake_1' },
    { text: "VERIFYING IP ADDRESS", effect: 'shake_2' },
    { text: "CHECKING NODE STATUS", effect: 'strong-glitch_1' },
    { text: "INITIATING HANDSHAKE PROTOCOL", effect: 'shake_3' },
    { text: "ACCESS GRANTED", effect: 'strong-glitch_2' },
    { text: "INITIALIZING SYSTEM COMPONENTS", effect: 'shake_1' },
    { text: "SYSTEM READY", effect: 'strong-glitch_3' },
    { text: "AUTHENTICATING USER", effect: 'shake_2' },
    { text: "LOADING MODULES", effect: 'strong-glitch_1' },
    { text: "FINALIZING SETUP", effect: 'shake_3' }
];


const scrambleText = (text) => {
    let scrambled = text.split('').map(char => Math.random() > 0.5 ? char : String.fromCharCode(33 + Math.random() * 94)).join('');
    return scrambled;
}

const TransitionPage = () => {
    const canvasRef = useRef(null);
    const navigate = useNavigate();
    const [currentCommand, setCurrentCommand] = useState('');
    const [effect, setEffect] = useState('');

    useEffect(() => {
        if (canvasRef.current) {
            startMatrixEffect(canvasRef.current, 0);
        }

        const showCommands = async () => {
            for (let i = 0; i < commands.length; i++) {
                for (let j = 0; j < 20; j++) {
                    setCurrentCommand(scrambleText(commands[i].text));
                    await new Promise(resolve => setTimeout(resolve, 50));
                }
                setCurrentCommand(commands[i].text);
                setEffect(commands[i].effect);
                await new Promise(resolve => setTimeout(resolve, 500)); // Duration of the effect
                setEffect('');
                await new Promise(resolve => setTimeout(resolve, 500));
            }

            const glitchClass = 'strong-glitch_3';
            setEffect(glitchClass);

            setTimeout(() => {
                navigate('/matrix');
            }, 500); // Duration of the final glitch effect
        };

        showCommands();
    }, [navigate]);

    return (
        <div className={`transition-page ${effect}`}>
            <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, pointerEvents: 'none' }}></canvas>
            <div className="command">
                {currentCommand}
            </div>
        </div>
    );
};

export default TransitionPage;
