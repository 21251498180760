// src/fullMatrix.js
export function startFullMatrixEffect(canvas) {
    const ctx = canvas.getContext('2d');
    const w = canvas.width = window.innerWidth;
    const h = canvas.height = window.innerHeight;
    const cols = Math.floor(w / 20) + 1;
    const ypos = Array(cols).fill(0);

    function matrix() {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
        ctx.fillRect(0, 0, w, h);

        ctx.fillStyle = '#0f0';
        ctx.font = '15pt monospace';

        ypos.forEach((y, index) => {
            const text = String.fromCharCode(33 + Math.random() * 94);
            const x = index * 20;
            ctx.fillText(text, x, y);
            if (y > 100 + Math.random() * 1e4) ypos[index] = 0;
            else ypos[index] = y + 20;
        });
    }

    setInterval(matrix, 50);
}
