import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SeizureBanner from './SeizureBanner';
import TransitionPage from './TransitionPage';
import MatrixPage from './MatrixPage';
import './App.css';

const App = () => {
    const audioRef = useRef(null);
    const [isAudioInitialized, setIsAudioInitialized] = useState(false);

    useEffect(() => {
        const handleUserClick = () => {
            if (audioRef.current && !isAudioInitialized) {
                audioRef.current.volume = 0.5; // Set volume to 50%
                audioRef.current.play().catch(error => {
                    console.error("Audio play failed:", error);
                });
                setIsAudioInitialized(true);
                window.removeEventListener('click', handleUserClick);
            }
        };

        window.addEventListener('click', handleUserClick);

        return () => {
            window.removeEventListener('click', handleUserClick);
        };
    }, [isAudioInitialized]);

    const MainRoute = () => {
        const navigate = useNavigate();

        useEffect(() => {
            const isVisited = Cookies.get('visited');
            if (isVisited === 'yes') {
                window.location.href = 'https://landing.doomer.ai';
            } else {
                navigate('/');
            }
        }, [navigate]);

        return null;
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<SeizureBanner />} />
                <Route path="/transition" element={<TransitionPage />} />
                <Route path="/matrix" element={<MatrixPage />} />
                <Route path="/main" element={<MainRoute />} />
            </Routes>
            <audio ref={audioRef} src="/sound.mp3" loop />
        </Router>
    );
};

export default App;
