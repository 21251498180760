// src/matrix.js
export function startMatrixEffect(canvas, duration) {
    const ctx = canvas.getContext('2d');
    const w = canvas.width = window.innerWidth;
    const h = canvas.height = window.innerHeight;
    const cols = Math.floor(w / 20) + 1;
    const ypos = Array(cols).fill(0);
    let opacity = 0;

    function matrix() {
        ctx.clearRect(0, 0, w, h); // Clear the canvas before drawing each frame

        ctx.fillStyle = `rgba(0, 255, 0, ${opacity})`;
        ctx.font = '15pt monospace';

        ypos.forEach((y, ind) => {
            const text = String.fromCharCode(Math.random() * 128);
            const x = ind * 20;
            ctx.fillText(text, x, y);
            if (y > 100 + Math.random() * 1e4) ypos[ind] = 0;
            else ypos[ind] = y + 20;
        });

        if (opacity < 1) {
            opacity += 0.02; // Gradually increase opacity
        }
    }

    setInterval(matrix, 50);

    setTimeout(() => {
        clearInterval(matrix);
    }, duration);
}
