import React, { useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { startFullMatrixEffect } from './fullMatrix';
import './MatrixPage.css';

const MatrixPage = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            startFullMatrixEffect(canvasRef.current);
        }
    }, []);

    const handleYesClick = () => {
        Cookies.set('visited', 'yes', { expires: 1/1440 }); // Set cookie for 1 minute
        window.location.href = 'https://landing.doomer.ai';
    };

    const handleNoClick = () => {
        Cookies.set('visited', 'no', { expires: 1/1440 }); // Set cookie for 1 minute
        window.location.href = 'https://google.com';
    };

    return (
        <div className="matrix-page" style={{ position: 'relative', backgroundColor: 'black', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, pointerEvents: 'none' }}></canvas>
            <div className="question" style={{ zIndex: 2 }}>
                <h1>Are you truly a doomed human?</h1>
                <div className="button-container">
                    <button className="matrix-button" onClick={handleYesClick}>YES</button>
                    <button className="matrix-button" onClick={handleNoClick}>NO</button>
                </div>
            </div>
        </div>
    );
};

export default MatrixPage;
